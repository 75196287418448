import fetcher from "@utils/Fetcher";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";

export const useCompanies = ({ sort, filter, limit, search }, config) => {
    const getKey = (pageIndex, previousPageData) => {
        let page = pageIndex + 1;
        let url = "company/get_all_companies";
        let parameters = [];
        if (sort !== null && sort !== undefined)
            parameters.push(`sort=${sort}`);
        if (filter !== null && sort !== undefined)
            parameters.push(`filter=${filter}`);
        if (page !== null && page !== undefined)
            parameters.push(`page=${page}`);
        if (limit !== null && limit !== undefined)
            parameters.push(`limit=${limit}`);
        if (search != null && search != undefined)
            parameters.push(`search=${search}`);
        if (parameters.length > 0) url += "?" + parameters.join("&");
        return url;
    };

    // Add url parameters
    return useSWRInfinite(getKey, fetcher, {
        parallel: true,
        revalidateFirstPage: false,
        initialSize: 1,
        ...config,
    });
};

export const useCompany = (companyID, config) => {
    return useSWR(companyID ? "company/" + companyID : null, fetcher, config);
};

export const useCompanyByName = (companyName, config) => {
    return useSWR(companyName ? "company/get_by_name/" + companyName : null, fetcher, config);
};

export const useCompanyCategories = (config) => {
    return useSWR("company_category", fetcher, config);
};

export const useCompanyDetails = (companyID, config) => {
    return useSWR(
        companyID ? "company/company_details/" + companyID : null,
        fetcher,
        config,
    );
};

export const useCompanyCertificates = (companyID, config) => {
    return useSWR(
        companyID ? "company_certificate/by_company/" + companyID : null,
        fetcher,
        config,
    );
};

export const useCompanyPopular = (config) => {
    return useSWR("company/get_popular", fetcher, config);
};
